.openit {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.openinside {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    padding: 35px;
    z-index: 9999;
}

.openinside h1 {
    font-size: 2em;
    letter-spacing: 3px;
    color: #01b7f2;
    margin: 0;
    margin-bottom: 15px;
}

.openinside p {
    margin: 0;
    font-size: 1.3em;
    color: #aaa;
    letter-spacing: 1px;
    padding: 0 !important;
}

@media(max-width:480px) {
    .openinside {
        width: 90%;
        padding: 15px;
    }

    .openinside p br {
        display: none;
    }

    .openinside h1 {
        font-size: 1.5em;
    }

    .openinside p {
        font-size: 1em;
    }
}